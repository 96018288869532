import { configureStore } from "@reduxjs/toolkit";

import dashboardReducer from "../views/Dashboard/dashboardSlice";
import searchReducer from "../views/SearchBox/searchSlice";
import accountTypesReducer from "../views/AccountTypes/accountTypesSlice";
import usersReducer from "../views/Users/usersSlice";
import labelsReducer from "../views/Users/labelsSlice";
import lastUpdatesReducer from "../views/LastUpdates/lastUpdatesSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    search: searchReducer,
    accountTypes: accountTypesReducer,
    users: usersReducer,
    labels: labelsReducer,
    lastUpdates: lastUpdatesReducer
  },
});
