import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getOverview } from "./dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "./HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { SalesSummary } from "../SalesSummary/SalesSummary";
import { Goods } from "../GoodsIn/GoodsIn";
import { SearchBox } from "../SearchBox/SearchBox";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";
import { SalesOrders } from "../SalesOrders/SalesOrders";
import { CSVLink } from "react-csv";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { valueCell } from "../../components/ValueCell/ValueCell";
import { dateCell } from "../../components/DateCell/DateCell";
import { TableWithRow as Table} from "../../components/TableWithRow/TableWithRow";

import css from "./Dashboard.module.css";

export const Dashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const defaultSortColumn = "Available_Stock:desc"
  const [page, setPage] = useState(1);
  const [upc, setUpc] = useState('');
  const [search, setSearch] = useState({search: [], format: [], range: {start: null, end: null}});
  const [sortDirection, setSortDirection] = useState(defaultSortColumn);
  const [showNotification, setShowNotification] = useState(false);

  // const page = new URLSearchParams(location.search).get("page") || 1;
  //const search = new URLSearchParams(location.search).get("search");
  //const sort = new URLSearchParams(location.search).get("sort");
  /*
  const setSortDirection = (dir) => {
    console.log('ssd');
    console.log(dir);
    ssd(dir);
    
  }
  */
  
  
  const onQueryParamsChange = ({newPage}) => {
    console.log('onQueryParamsChange');
    setPage(page);
//     const queryParams = {
//       page,
//       sort: sortDirection
//     };
// 
//     const queryString = buildQueryString("/dashboard", queryParams);
//     navigate(queryString);
  };
    
  const { overview, getOverviewInProgress } =
    useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = overview || {};

  useEffect(() => {
    dispatch(
      getOverview({
        page,
        search,
        sort: sortDirection,
      }),
    );
  }, []);


  const handleTableChange = (pagination, filters, sorter) => {
    let { current: page } = pagination;
    const { field, order } = sorter;
    const sort = (order === undefined) ?  defaultSortColumn : `${field}:${order.replace('end', '')}`;
    
    if(sort != sortDirection)
      page = 1;
      
    setPage(page);
    setSortDirection(sort);
    
    dispatch(
      getOverview({
        page,
        search,
        sort
      })
    );
  };
  
  const columns = [
    {
      title: "Artist",
      editable: true,
      dataIndex: "Artist",
      width: 250,
      sorter: true,
      align: "left",
    },
    {
      title: "Title",
      editable: true,
      dataIndex: "Title",
      width: 250,
      sorter: true,
      align: "left",
    },
    {
      title: "Catalogue Number",
      editable: true,
      dataIndex: "Catalogue_Number",
      sorter: true,
      width: 100,
      align: "left",
    },
    {
      title: "Format",
      editable: true,
      dataIndex: "Format",
      sorter: true,
      width: 150,
      align: "left",
    },
    {
      title: "UPC",
      editable: true,
      dataIndex: "UPC",
      sorter: true,
      width: 200,
      align: "left",
    },
    {
      title: "Release Date",
      editable: true,
      dataIndex: "Release_Date",
      sorter: true,
      width: 200,
      align: "left",
      render: (record) => dateCell(record)
    },    
    {
      title: "UK PPD (£)",
      editable: true,
      dataIndex: "UK_PPD",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },
    {
      title: "IE PPD (€)",
      editable: true,
      dataIndex: "IE_PPD",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },    
    {
      title: "Av. Stock",
      editable: true,
      dataIndex: "Available_Stock",
      sorter: true,
      width: 150,
      align: "left",
      defaultSortOrder: 'descend',
      render: (record) => valueCell(record)
    },
    {
      title: "Unav. Stock",
      editable: true,
      dataIndex: "Unavailable_Stock",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },
    {
      title: "Total Stock",
      editable: true,
      dataIndex: "Total_Warehouse_Stock",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },
    {
      title: "Cons. Stock",
      editable: true,
      dataIndex: "Consignment_Stock",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },    
    {
      title: "Cons. Ship",
      editable: true,
      dataIndex: "Consignment_Ship",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },    
    {
      title: "Sold Units",
      editable: true,
      dataIndex: "Sold_Units",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },    
    {
      title: "FOC Ship",
      editable: true,
      dataIndex: "FOC_Ship",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },   
    {
      title: "Orders",
      editable: true,
      dataIndex: "Orders",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },
    {
      title: "Pre Orders",
      editable: true,
      dataIndex: "Pre_Orders",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },
    {
      title: "Goods In",
      editable: true,
      dataIndex: "Goods_In",
      sorter: true,
      width: 150,
      align: "left",
      render: (record) => valueCell(record)
    },


 

    /*
    {
      title: "Actions",
      dataIndex: "",
      fixed: "right",
      key: "x",
      width: 100,
      align: "left",
      render: (record) => {
        return (
          <Flex gap={16} align={"center"}>
            <div className={css.actionContainer}>
              <span
                className={css.actionIcon}
                onClick={() => {
                  navigate(`/channels/edit/${record.id}`);
                }}
              >
              </span>
            </div>
          </Flex>
        );
      },
    },*/
  ];

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setUpc(record.UPC);  
      }
    };
  };
  
  const onApplySearch = (search) => {
    setSearch(search);
    setPage(1);
    
    dispatch(
      getOverview({
        page: 1,
        search,
        sort: sortDirection,
      }),
    );

  }
  
  const rowClassName = (record) => {
    return record.UPC === upc ? 'selected-row' : '';
  };

  return (<>
    {/* <PageHeader title="Stock and Sales Dashboard" /> */}
    <Breadcrumbs paths={[{path: `/dashboard/`, breadcrumbName: `Dashboard`}]} />
    <PageHeader title="Stock and Sales Dashboard">
      <CSVLink data={rows || []} filename="overview.csv">
        <IconDownload />
      </CSVLink>

    </PageHeader>
    <Flex className={css.root} vertical>
      <div className={"ibox-holder"}>
        <SearchBox onApply={onApplySearch} />
      </div>
      
      <div className={"ibox-holder"}>
        <SectionHeader title="Search Table">
          <CSVLink data={rows || []} filename="overview.csv">
            <IconDownload />
          </CSVLink>
        </SectionHeader>
        <Form name="tableForm" form={form} component={false}>
          <Table
            components={{
              header: {
                row: HeaderCell,
              },
            }}
            columns={columns}
            rowKey={(record) => record.id}
            onRow={onRow}
            dataSource={rows}
            pagination={{
              position: ["bottomRight"],
              responsive: true,
              current: page,
              total: totalPages,
              pageSize: 10,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            loading={getOverviewInProgress}
            style={{ marginTop: 10 }}
            scroll={{ x: "100%" }}
            rowClassName={rowClassName}
            onChange={handleTableChange}
          />
        </Form>
      </div>
      {upc && <div className={"ibox-holder"}><SalesSummary upc={upc} /></div>}
      {upc && <div className={"ibox-holder"}><SalesOrders upc={upc} /></div>}
    </Flex>
  </>
  );
};
