import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table, Tooltip } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getGoods } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { valueCell } from "../../components/ValueCell/ValueCell";
import { CSVLink } from "react-csv";
import SortableTable from "../../components/SortableTable/SortableTable";

export const Goods = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  const { goods, getGoodsInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = goods || {};

  useEffect(() => {
	dispatch(
	  getGoods({
		upc,
	  }),
	);
  }, [upc]);

  const columns = [
	/*  
	{
		title: "UPC",
		showSorterTooltip: false,
		editable: true,
		dataIndex: "UPC",
		width: 250,
		sorter: true,
		align: "left",
  	},
	*/
	{
	  title: (
			<Tooltip title="Catalogue Number">
				<div>Catalogue Number</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "Catalogue_Number",
	  sorter: true,
	  width: 200,
	  align: "left",
		showSorterTooltip: false,
	},
  
	{
		title: (
			<Tooltip title="The date delivery was received">
				<div>Receipt Date</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Receipt_Date",
	  width: 250,
	  sorter: true, 
	  align: "left",
	  render: (record) => dateCell(record)
	},
	{
		title: (
			<Tooltip title="Reference">
				<div>Reference</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Reference",
	  width: 250,
	  sorter: true, 
	  align: "left",
	},
	{
		title: (
			<Tooltip title="Order Number">
				<div>Order Number</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Order_Number",
	  width: 100,
	  sorter: true, 
	  align: "left",
	  render: (record) => valueCell(record)
	},
	{
		title: (
			<Tooltip title="Quantity Put Away">
				<div>Total Qty</div>
			</Tooltip>
		),
		showSorterTooltip: false,
	  editable: true,
	  dataIndex: "Total_Qty",
	  width: 100,
	  sorter: true, 
	  align: "left",
	  render: (record) => valueCell(record)
	}
  ];

  return (
		<Flex className={""} vertical>
			<SectionHeader title="Goods In Summary">
				<CSVLink data={rows || []} filename="goods_in.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<SortableTable
				components={{
					header: {
						row: HeaderCell,
					},
				}}
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={rows}
				pagination={{
					position: ["bottomRight"],
					responsive: true,
					total: totalPages,
					pageSize: 10,
					hideOnSinglePage: true,
					showSizeChanger: false,
				}}
				loading={getGoodsInProgress}
				style={{ marginTop: 10 }}
				scroll={{ x: "100%" }}
			/>
		</Flex>
  );
};
