import React from 'react';
import { Table as AntTable } from 'antd';

const compare = (a, b) => {
	if(a === null)
		a = typeof b == 'string' ? '' : 0;
	if(b === null)
		b = typeof a == 'string' ? '' : 0;
		
	if(typeof a == 'string' && typeof b == 'string') {
		return a.localeCompare(b);
	}
	else
		return a < b;
}

const Table = props => {
  const {
	columns,
	...otherTableProps
  } = props;

  const sortableColumns = columns.map(column => {
	const { sorter, dataIndex, ...otherColumnProps } = column;

	if (sorter) {

	  return {
		...otherColumnProps,
		dataIndex,
		sorter: {
		  compare: (rowA, rowB) => compare(rowA[dataIndex], rowB[dataIndex]),
		},
		sortDirections: ['ascend', 'descend', 'ascend']
	  };
	}

	return column;
  });

  return (
	<AntTable
	  columns={sortableColumns}
	  {...otherTableProps}
	/>
  );
};

export default Table;
