// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMenu_mainMenu__vUClK {
	width: 208px; 

	& li {
		text-align: left;
		margin: 0;
		margin-inline: 0;
		margin-block: 0;
		width: 100%;
	}
}

.ant-menu-submenu-title,
.ant-menu-item {
	margin: 0 !important;
	margin-inline: 0;
	margin-block: 0;
	width: 100% !important;
	border-radius: 0 !important;
	border-right: 3px solid transparent;
}

.ant-menu-item-selected {
	border-color: rgba(24, 144, 255, 1);

}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
	color: rgba(0, 0, 0, 0.88);
}

.ant-menu-light .ant-menu-submenu-selected.ant-menu-submenu-open >.ant-menu-submenu-title {
	color: rgba(24, 144, 255, 1);
}`, "",{"version":3,"sources":["webpack://./src/components/MainMenu/MainMenu.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;;CAEZ;EACC,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;CACZ;AACD;;AAEA;;CAEC,oBAAoB;CACpB,gBAAgB;CAChB,eAAe;CACf,sBAAsB;CACtB,2BAA2B;CAC3B,mCAAmC;AACpC;;AAEA;CACC,mCAAmC;;AAEpC;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,4BAA4B;AAC7B","sourcesContent":[".mainMenu {\n\twidth: 208px; \n\n\t& li {\n\t\ttext-align: left;\n\t\tmargin: 0;\n\t\tmargin-inline: 0;\n\t\tmargin-block: 0;\n\t\twidth: 100%;\n\t}\n}\n\n:global(.ant-menu-submenu-title),\n:global(.ant-menu-item) {\n\tmargin: 0 !important;\n\tmargin-inline: 0;\n\tmargin-block: 0;\n\twidth: 100% !important;\n\tborder-radius: 0 !important;\n\tborder-right: 3px solid transparent;\n}\n\n:global(.ant-menu-item-selected) {\n\tborder-color: rgba(24, 144, 255, 1);\n\n}\n\n:global(.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title) {\n\tcolor: rgba(0, 0, 0, 0.88);\n}\n\n:global(.ant-menu-light .ant-menu-submenu-selected.ant-menu-submenu-open >.ant-menu-submenu-title) {\n\tcolor: rgba(24, 144, 255, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenu": `MainMenu_mainMenu__vUClK`
};
export default ___CSS_LOADER_EXPORT___;
