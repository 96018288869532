import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

import { NotFound } from "./views/NotFound/NotFound";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { Login } from "./views/Login/Login";
import { AccountTypes } from "./views/AccountTypes/AccountTypes";
import { EditAccountType } from "./views/EditAccountType/EditAccountType";
import { Users } from "./views/Users/Users";
import { EditUser } from "./views/EditUser/EditUser";
import { CreateUser } from "./views/CreateUser/CreateUser";
import { ChangePassword } from "./views/ChangePassword/ChangePassword";
import { UserDetails } from "./views/UserDetails/UserDetails";
import { ForgotPassword } from "./views/ForgotPassword/ForgotPassword";

const routes = [
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Dashboard />} />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "/login",
    element: <Login />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "/account-types/edit",
    element: <EditAccountType />,
    login: true,
    // Add additional configuration options as needed
  },
  
  {
    path: "/account-types",
    element: <PrivateRoute adminOnly={true} element={<AccountTypes />} />,
    login: true,
    // Add additional configuration options as needed
  },

  {
    path: "*",
    element: <NotFound />,
    // Add additional configuration options as needed
  },
  {
    path: "/admin/users",
    element: <PrivateRoute adminOnly={true} element={<Users />} />,
    login: true,
    adminOnly: true,
    // Add additional configuration options as needed
  },
  {
    path: "/admin/users/edit/",
    element: <PrivateRoute adminOnly={true} element={<EditUser />} />,
    login: true,
    adminOnly: true,
    // Add additional configuration options as needed
  },
  {
    path: "/admin/users/new/",
    element: <PrivateRoute adminOnly={true} element={<CreateUser />} />,
    login: true,
    adminOnly: true,
    // Add additional configuration options as needed
  },
  {
    path: "/user/:id/change_password",
    element: <PrivateRoute element={<ChangePassword />} />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "/user/:id/details",
    element: <PrivateRoute element={<UserDetails />} />,
    login: true,
    // Add additional configuration options as needed
  },
  {
    path: "/forgot_password",
    element: <PrivateRoute  element={<ForgotPassword />} forgotPassword={true} />,
    login: true,
    // Add additional configuration options as needed
  },
];

export default routes;
