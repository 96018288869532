// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item-explain {
  text-align: left !important;
  margin-top: 3px;
}

.ChangePassword_root__seP0L {
  margin: 20px 20px;
}

.ChangePassword_dashedLine__8XSbf {
  border-top: 1px dashed #d2d1d4;
  margin-bottom: 10px;
  width: 100%;
}

.ChangePassword_breadcrumb__k62co {
  margin-bottom: 20px;
}

.ChangePassword_datePicker__d26aV {
  width: 100%;
}

.ChangePassword_label__1SCtZ {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.ChangePassword_formItem__fFog3 {
  margin-bottom: 10px;
}

.ChangePassword_submitButton__KoEdV, 
.ChangePassword_backButton__6hA4L {
  margin-top: 15px;
  width: 100%;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/views/ChangePassword/ChangePassword.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB","sourcesContent":[":global(.ant-form-item-explain) {\n  text-align: left !important;\n  margin-top: 3px;\n}\n\n.root {\n  margin: 20px 20px;\n}\n\n.dashedLine {\n  border-top: 1px dashed #d2d1d4;\n  margin-bottom: 10px;\n  width: 100%;\n}\n\n.breadcrumb {\n  margin-bottom: 20px;\n}\n\n.datePicker {\n  width: 100%;\n}\n\n.label {\n  font-size: 14px !important;\n  font-weight: 600 !important;\n}\n\n.formItem {\n  margin-bottom: 10px;\n}\n\n.submitButton, \n.backButton {\n  margin-top: 15px;\n  width: 100%;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ChangePassword_root__seP0L`,
	"dashedLine": `ChangePassword_dashedLine__8XSbf`,
	"breadcrumb": `ChangePassword_breadcrumb__k62co`,
	"datePicker": `ChangePassword_datePicker__d26aV`,
	"label": `ChangePassword_label__1SCtZ`,
	"formItem": `ChangePassword_formItem__fFog3`,
	"submitButton": `ChangePassword_submitButton__KoEdV`,
	"backButton": `ChangePassword_backButton__6hA4L`
};
export default ___CSS_LOADER_EXPORT___;
