import React, { useState, useEffect } from "react";
import { Select, Button } from "antd";

import css from "./MultiSelectTransfer.module.css";

const MultiSelectTransfer = ({
  availableOptions = [],
  selectedOptions = [],
  onChange,
  labelKey = "name",
  valueKey = "id",
}) => {
  const [available, setAvailable] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedAvailable, setSelectedAvailable] = useState([]);
  const [selectedSelected, setSelectedSelected] = useState([]);

  useEffect(() => {
    const availableFiltered = availableOptions.filter(
      (option) => !selectedOptions.some((selected) => selected[valueKey] === option[valueKey])
    );
    setAvailable(availableFiltered);
    setSelected(selectedOptions);
  }, [availableOptions, selectedOptions]);

  const handleMoveToSelected = () => {
    const toMove = available.filter((item) => selectedAvailable.includes(item[valueKey]));
    const newSelected = [...selected, ...toMove];
    const newAvailable = available.filter((item) => !selectedAvailable.includes(item[valueKey]));
    setSelected(newSelected);
    setAvailable(newAvailable);
    setSelectedAvailable([]);
    onChange(newSelected);
  };

  const handleMoveToAvailable = () => {
    const toMove = selected.filter((item) => selectedSelected.includes(item[valueKey]));
    const newAvailable = [...available, ...toMove];
    const newSelected = selected.filter((item) => !selectedSelected.includes(item[valueKey]));
    setAvailable(newAvailable);
    setSelected(newSelected);
    setSelectedSelected([]);
    onChange(newSelected);
  };

  const handleMoveAllToSelected = () => {
    const newSelected = [...selected, ...available];
    setSelected(newSelected);
    setAvailable([]);
    setSelectedAvailable([]);
    onChange(newSelected);
  };

  const handleMoveAllToAvailable = () => {
    const newAvailable = [...available, ...selected];
    setAvailable(newAvailable);
    setSelected([]);
    setSelectedSelected([]);
    onChange([]);
  };

  return (
    <div className={css.root}>
      <div id='selectItemAvailable' className={css.item}>
        <label>All labels</label>
        <Select
          mode="multiple"
          value={selectedAvailable}
          onChange={setSelectedAvailable}
          placeholder={'Choose labels'}
          options={available.map((option) => ({
            value: option[valueKey],
            label: option[labelKey],
          }))}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          open
          getPopupContainer={() => document.getElementById('selectItemAvailable')}
        />
      </div>

      <div className={css.buttons}>
        <Button onClick={handleMoveToSelected} disabled={selectedAvailable.length === 0}>
          &gt;
        </Button>
        <Button onClick={handleMoveToAvailable} disabled={selectedSelected.length === 0}>
          &lt;
        </Button>
        <Button onClick={handleMoveAllToSelected} disabled={available.length === 0}>
          &gt;&gt;
        </Button>
        <Button onClick={handleMoveAllToAvailable} disabled={selected.length === 0}>
          &lt;&lt;
        </Button>
      </div>

      <div id='selectItemGranted' className={css.item}>
        <label>Access granted</label>
        <Select
          mode="multiple"
          value={selectedSelected}
          onChange={setSelectedSelected}
          placeholder={'Choose labels'}
          options={selected.map((option) => ({
            value: option[valueKey],
            label: option[labelKey],
          }))}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          open
          getPopupContainer={() => document.getElementById('selectItemGranted')}
        />

      </div>
    </div>
  );
};

export default MultiSelectTransfer;
