import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLastUpdatesRequest,
  updateAccountTypeRequest,
} from "../../api";

const initialState = {
  lastUpdates: {},
  getLastUpdatesInProgress: false,
  getLastUpdatesError: null,
};

export const getLastUpdates = createAsyncThunk(
  "lastUpdates/getLastUpdates",
  async (_, { rejectWithValue }) => {
	try {
	  const response = await getLastUpdatesRequest();
	  return response.data;
	} catch (error) {
	  return rejectWithValue(error.message);
	}
  },
);

export const lastUpdatesSlice = createSlice({
  name: "lastUpdates",
  initialState,
  reducers: {
	clearData(state) {
	  state.lastUpdates = null;
	},
  },
  extraReducers: (builder) => {
	builder
	  .addCase(getLastUpdates.pending, (state) => {
		state.getLastUpdatesInProgress = true;
		state.getLastUpdatesError = null;
	  })
	  .addCase(getLastUpdates.fulfilled, (state, action) => {
		let {payload} = action;
		let mappedResult = {};
		for(let row of payload) {
			mappedResult[row.source] = row.date;
		}
		state.lastUpdates = mappedResult;
		state.getLastUpdatesInProgress = false;
		state.getLastUpdatesError = null;
	  })
	  .addCase(getLastUpdates.rejected, (state, action) => {
		state.getLastUpdatesInProgress = false;
		state.getLastUpdatesError = action.payload;
	  });
  },
});

export const { clearData } = lastUpdatesSlice.actions;

// this is for configureStore
export default lastUpdatesSlice.reducer;
