import React from "react";
import css from "./SectionHeader.module.css";

export const SectionHeader = ({ title, children = null }) => {
  return (
    <div className={css.headerContainer}>
      <h2 className={css.title}>{title}</h2>
      {children}
    </div>
  );
};
