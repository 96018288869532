import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Input, Row, Select, Button, Flex } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

import css from "./UserDetails.module.css";

export const UserDetails = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { name, email, role, id } = currentUser;

  return (<>
      <Breadcrumbs 
        basePath="user" 
        paths={[
          { path: '/user/:id/details', breadcrumbName: 'User Details' },
        ]}
      />
      <PageHeader title="User Details" />
    
      <Flex className={css.root} vertical>
        <div className={"ibox-holder"}>
          <Flex gap={12} vertical>
            <Row gutter={16}>
              <Col>
                <b>Name: </b> {name}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <b>Email: </b> {email}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <b>Role: </b> {role}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Button type="primary" size="medium">
                  <Link to={`/user/${id}/change_password`}>Change Password</Link>
                </Button>
              </Col>
            </Row>
          </Flex>
        </div>
      </Flex>
    </>
  );
};
