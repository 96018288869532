import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Col, Flex, Form, Input, Row, Select, Button } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { MainButton } from "../../components/MainButton/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { rolesOptions } from "../../constants/roles";
import { updateUser } from "../Users/usersSlice";
import { getLabels, clearData } from "../Users/labelsSlice";
import MultiSelectTransfer from "../../components/MultiSelectTransfer/MultiSelectTransfer";

import css from "./EditUser.module.css";

export const EditUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = useForm();

  const [initialValues, setInitialValues] = useState(null);
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);

  const { labels, getLabelsInProgress } =
    useSelector((state) => state?.labels) || {};

  const onSubmit = async (values) => {
    await dispatch(updateUser({ data: { 
      ...values, 
      label_ids: selectedLabelIds, 
      person_id: initialValues.id,
    } 
  }));

    form.resetFields();
    navigate("/admin/users", { state: { from: "/userEdited" } });
  };

  useEffect(() => {
    if (location.state?.rowData) {
      const rowData = location.state.rowData;
      setInitialValues(rowData);
      form.setFieldsValue(rowData);
  
      if (rowData.label_ids) {
        setSelectedLabelIds(rowData.label_ids);
      }
    }
  }, [location?.state, form]);

  useEffect(() => {
    dispatch(getLabels());

    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  const rules = [
    {
      required: true,
      message: "This field is required",
    },
  ];

  return (
    <>
      <Breadcrumbs
        basePath="admin"
        paths={[
          { path: `/admin/users/`, breadcrumbName: `Users Management` },
          { path: `/users/edit`, breadcrumbName: `Edit User` },
        ]}
      />
      <PageHeader title="Edit User" />

      <Flex className={css.root} vertical>
        <div className={"ibox-holder"}>
          <Form
            form={form}
            name="editUserForm"
            style={{ marginTop: 10, marginBottom: 0 }}
            initialValues={initialValues}
            autoComplete="off"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="email"
                  className={css.formItem}
                  label={<span className={css.label}>Email</span>}
                  rules={rules}
                >
                  <Input
                    size="large"
                    placeholder="Email"
                    disabled={true}
                    style={{ color: "#737373" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="name"
                  className={css.formItem}
                  label={<span className={css.label}>Name</span>}
                  rules={rules}
                >
                  <Input
                    size="large"
                    placeholder="Name"
                    style={{ color: "#737373" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="role"
                  className={css.formItem}
                  label={<span className={css.label}>Role</span>}
                  rules={rules}
                >
                  <Select
                    allowClear
                    placeholder="Role"
                    align="left"
                    size="large"
                    options={rolesOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="labels"
                className={css.formItem}
                label={<span className={css.label}>Labels</span>}
              >
                <MultiSelectTransfer
                  availableOptions={labels}
                  selectedOptions={labels.filter((label) =>
                    selectedLabelIds.includes(label.id)
                  )}
                  onChange={(selected) =>
                    setSelectedLabelIds(selected.map((label) => label.id))
                  }
                  labelKey="name"
                  valueKey="id"
                />
              </Form.Item>

              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6} md={24} lg={12} xl={4}>
                <Row gutter={16}>
                  <Col xs={12} md={24} lg={12} xl={12}>
                    <Form.Item>
                      <MainButton
                        size="large"
                        rootClassName={css.submitButton}
                        htmlType="submit"
                        loading={false}
                      >
                        Edit User
                      </MainButton>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={24} lg={12} xl={12}>
                      <Button type="default" size="large" className={css.backButton}>
                        <Link to="/admin/users">Back</Link>
                      </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Flex>
    </>
  );
};
