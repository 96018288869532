// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item-explain {
    text-align: left !important;
    margin-top: 3px;
}

.EditUser_root__Gnh0a {
    margin: 20px 20px;
}

.EditUser_dashedLine__rgStE {
    border-top: 1px dashed #d2d1d4;
    margin-bottom: 10px;
    width: 100%;
}

.EditUser_breadcrumb__cbIpD {
    margin-bottom: 20px;
}

.EditUser_datePicker__2xyEn {
    width: 100%;
}

.EditUser_label__qucR9 {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.EditUser_formItem__nOnfa {
    margin-bottom: 10px;
}

.EditUser_submitButton__sJvRt, 
.EditUser_backButton__d2lBs {
    margin-top: 15px;
    width: 100%;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/views/EditUser/EditUser.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB","sourcesContent":[":global(.ant-form-item-explain) {\n    text-align: left !important;\n    margin-top: 3px;\n}\n\n.root {\n    margin: 20px 20px;\n}\n\n.dashedLine {\n    border-top: 1px dashed #d2d1d4;\n    margin-bottom: 10px;\n    width: 100%;\n}\n\n.breadcrumb {\n    margin-bottom: 20px;\n}\n\n.datePicker {\n    width: 100%;\n}\n\n.label {\n    font-size: 14px !important;\n    font-weight: 600 !important;\n}\n\n.formItem {\n    margin-bottom: 10px;\n}\n\n.submitButton, \n.backButton {\n    margin-top: 15px;\n    width: 100%;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EditUser_root__Gnh0a`,
	"dashedLine": `EditUser_dashedLine__rgStE`,
	"breadcrumb": `EditUser_breadcrumb__cbIpD`,
	"datePicker": `EditUser_datePicker__2xyEn`,
	"label": `EditUser_label__qucR9`,
	"formItem": `EditUser_formItem__nOnfa`,
	"submitButton": `EditUser_submitButton__sJvRt`,
	"backButton": `EditUser_backButton__d2lBs`
};
export default ___CSS_LOADER_EXPORT___;
