import { uniq, map, find } from 'lodash';
export const _salesOrdersFormatter = (data) => {
	let rows = [];
	if(Array.isArray(data) && data.length) {
		let upcs = uniq(map(data, 'UPC'));
		for(let upc of upcs) {
			let row = {upc};
			let total = 0;
			let sales = data.filter(i=>i.UPC == upc);
			for(let sale of sales) {
				row[sale.Group_Date] = sale.Quantity;
				total += sale.Quantity;
			}
			row.total = total;
			rows.push(row);
		}
	}
	return rows;
}

export const salesOrdersFormatter = (data) => {
	let rows = [];
	if(Array.isArray(data) && data.length) {
		let numbers = uniq(map(data, 'Catalogue_Number'));
		for(let number of numbers) {
			let row = {Catalogue_Number: number};
			let total = 0;
			let sales = data.filter(i=>i.Catalogue_Number == number);
			for(let sale of sales) {
				row[sale.Group_Date] = sale.Quantity;
				total += sale.Quantity;
			}
			row.total = total;
			rows.push(row);
		}
	}
	return rows;
}