// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Roboto", sans-serif;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 65px;
  }
}

h2 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,SAAS;EACT,UAAU;EACV,iCAAiC;EACjC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE,SAAS;EACT,UAAU;EACV,iCAAiC;EACjC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["* {\n  font-family: \"Roboto\", sans-serif;\n}\n\nh1 {\n  margin: 0;\n  padding: 0;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 36px;\n  font-weight: 800;\n  line-height: 48px;\n\n  @media (min-width: 768px) {\n    font-size: 48px;\n    line-height: 65px;\n  }\n}\n\nh2 {\n  margin: 0;\n  padding: 0;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 29px;\n  letter-spacing: 0em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
