// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionHeader_headerContainer__YoRs6 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.SectionHeader_title__T18zH {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/SectionHeader/SectionHeader.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;AAClB","sourcesContent":[".headerContainer {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tmargin-bottom: 8px;\n}\n\n.title {\n\tfont-size: 16px;\n\tfont-weight: 500;\n\tline-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `SectionHeader_headerContainer__YoRs6`,
	"title": `SectionHeader_title__T18zH`
};
export default ___CSS_LOADER_EXPORT___;
