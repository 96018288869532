// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item-explain {
    text-align: left !important;
    margin-top: 3px;
}

.EditAccountType_root__UkGpR {
    margin: 20px 20px;
    @media (min-width: 768px) {
        margin: 20px 40px;
    }
}

.EditAccountType_dashedLine__i5Ghj {
    border-top: 1px dashed #d2d1d4;
    margin-bottom: 10px;
    width: 100%;
}

.EditAccountType_breadcrumb__DCtGX {
    margin-bottom: 20px;
}

.EditAccountType_datePicker__q9Sfq {
    width: 100%;
}

.EditAccountType_label__z8z6b {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.EditAccountType_formItem__fXemO {
    margin-bottom: 10px;
}

.EditAccountType_submitButton__q7yxy {
    margin-top: 15px;
    width: 100%;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/views/EditAccountType/EditAccountType.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB","sourcesContent":[":global(.ant-form-item-explain) {\n    text-align: left !important;\n    margin-top: 3px;\n}\n\n.root {\n    margin: 20px 20px;\n    @media (min-width: 768px) {\n        margin: 20px 40px;\n    }\n}\n\n.dashedLine {\n    border-top: 1px dashed #d2d1d4;\n    margin-bottom: 10px;\n    width: 100%;\n}\n\n.breadcrumb {\n    margin-bottom: 20px;\n}\n\n.datePicker {\n    width: 100%;\n}\n\n.label {\n    font-size: 14px !important;\n    font-weight: 600 !important;\n}\n\n.formItem {\n    margin-bottom: 10px;\n}\n\n.submitButton {\n    margin-top: 15px;\n    width: 100%;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EditAccountType_root__UkGpR`,
	"dashedLine": `EditAccountType_dashedLine__i5Ghj`,
	"breadcrumb": `EditAccountType_breadcrumb__DCtGX`,
	"datePicker": `EditAccountType_datePicker__q9Sfq`,
	"label": `EditAccountType_label__z8z6b`,
	"formItem": `EditAccountType_formItem__fXemO`,
	"submitButton": `EditAccountType_submitButton__q7yxy`
};
export default ___CSS_LOADER_EXPORT___;
