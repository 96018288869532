import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { TableOutlined, DashboardOutlined } from '@ant-design/icons';
import css from './MainMenu.module.css';

export const MainMenu = () => {
	const currentUser = JSON.parse(localStorage.getItem("user"));
	const role = currentUser?.role;

	const items = [
	  {
			key: 'stock',
			icon: <TableOutlined />,
			label: 'Stock',
			children: [
				{
				key: 'stock_sales',
				label: 'Stock and Sales',
				children: [
					{
					key: 'dashboard',
					label: <Link to='/dashboard'>Dashboard</Link>
					},
				],
				},
			],
	  },
		{
			key: 'admin',
			icon: <DashboardOutlined />,
			label: 'Admin',
			role: 'admin',
			children: [
				{
					key: 'account-types',
					label: <Link to='/account-types'>Account Types</Link>
					},
				{
					key: 'users',
					label: <Link to='/admin/users'>User Management</Link>
				},
			],
		},
	];
	
	const [current, setCurrent] = useState('dashboard');
	
	const onClick = (e) => {
  		console.log('click', e);
		setCurrent(e.key);
	};

	const filterMenuItems = (menuItems) =>
    menuItems
      .filter((item) => !item.role || item.role === role) 
      .map((item) => ({
        ...item,
        children: item.children ? filterMenuItems(item.children) : undefined,
      }));

  const filteredItems = filterMenuItems(items);

  return (
  	<Menu
			onClick={onClick}
			className={css.mainMenu}
			mode="inline"
			selectedKeys={[current]}
			defaultOpenKeys={['stock']}
			items={filteredItems}
		/>	
  )
}