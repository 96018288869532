import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { forgotPasswordRequest } from "../../api";

const initialState = {
  forgotPasswordInProgress: false,
  forgotPasswordError: null, 
};

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await forgotPasswordRequest(data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearData(state) {
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordInProgress = true;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordInProgress = false;
        state.forgotPasswordError = null;
        state.forgotPasswordMessage = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordInProgress = false;
        state.forgotPasswordError = action.payload;
      })
  },
});

export const { clearData } = userSlice.actions;

export default userSlice.reducer;