// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../shared/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../shared/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../shared/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./customMediaQueries.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --colorRedLight: #0030CC;
    --colorRedDark: #0029a2;
    --colorBlackLight: #171717;
    --colorBlackDark: #4C4C4C;
    --colorGrayLight: #FAFAFA;
}

.selected-row {
  background-color: #EFEFEF;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAEA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@import './customMediaQueries.css';\n\n:root {\n    --colorRedLight: #0030CC;\n    --colorRedDark: #0029a2;\n    --colorBlackLight: #171717;\n    --colorBlackDark: #4C4C4C;\n    --colorGrayLight: #FAFAFA;\n}\n\n.selected-row {\n  background-color: #EFEFEF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
