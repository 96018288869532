import { uniq, map, find } from 'lodash';
export const salesOrdersFormatter = (data) => {
	let rows = [];
	let upcs = uniq(map(data, 'UPC'));
	for(let upc of upcs) {
		let row = {upc};
		let total = 0;
		let sales = data.filter(i=>i.UPC == upc);
		for(let sale of sales) {
			row[sale.Group_Date] = sale.Quantity;
			total += sale.Quantity;
		}
		row.total = total;
		rows.push(row);
	}
	return rows;
}