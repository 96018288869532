// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__kXlYa {
  text-align: center;
  color: #171717;
  background-color: #ffffff;
  border-top: 1px solid #73737333;
  min-height: 40px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    padding: 10px 40px;
  }
}

.Footer_footerInner__Lmt4V {
  width: 100%;
}

.Footer_rowLeft__iiS9k {
  width: 50%;
  text-align: left;
  align-items: center;
  gap: 14px;
}

.Footer_rowRight__BbuxN {
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

.Footer_col__uMvMM {
  cursor: pointer;
  margin-bottom: 5px;
}

.Footer_help__TPCht {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.Footer_copy__V37yO {
  color: rgba(0, 0, 0, 0.45);
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,WAAW;;EAEX;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;;EAEhB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".footer {\n  text-align: center;\n  color: #171717;\n  background-color: #ffffff;\n  border-top: 1px solid #73737333;\n  min-height: 40px;\n  padding: 10px 20px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  width: 100%;\n\n  @media (min-width: 768px) {\n    padding: 10px 40px;\n  }\n}\n\n.footerInner {\n  width: 100%;\n}\n\n.rowLeft {\n  width: 50%;\n  text-align: left;\n  align-items: center;\n  gap: 14px;\n}\n\n.rowRight {\n  width: 50%;\n  justify-content: flex-start;\n  align-items: center;\n  text-align: left;\n\n  @media (min-width: 768px) {\n    justify-content: flex-end;\n  }\n}\n\n.col {\n  cursor: pointer;\n  margin-bottom: 5px;\n}\n\n.help {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  font-size: 20px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.copy {\n  color: rgba(0, 0, 0, 0.45);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__kXlYa`,
	"footerInner": `Footer_footerInner__Lmt4V`,
	"rowLeft": `Footer_rowLeft__iiS9k`,
	"rowRight": `Footer_rowRight__BbuxN`,
	"col": `Footer_col__uMvMM`,
	"help": `Footer_help__TPCht`,
	"copy": `Footer_copy__V37yO`
};
export default ___CSS_LOADER_EXPORT___;
