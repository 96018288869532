import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Col, Flex, Form, Input, Row, Select, Table, Radio, Button, Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getUsers, clearData, getCurrentUserDetails, deleteUser } from "./usersSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "./HeaderCell";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { IconEdit } from "../../components/IconEdit/IconEdit";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { UserSearch } from "../UserSearch/UserSearch";

import css from "./Users.module.css";

export const Users = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { users, getUsersInProgress } = useSelector((state) => state.users);

  const [filteredData, setFilteredData] = useState([]);
  const [searchFilters, setSearchFilters] = useState({ name: '', email: '' }); 

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCurrentUserDetails());

    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (users?.data) {
      const filtered = users.data.filter((user) => {
        const matchesName = 
          !searchFilters.name ||
          (user.name && user.name.toLowerCase().includes(searchFilters.name.toLowerCase())); 
        const matchesEmail = 
          !searchFilters.email || 
          (user.email && user.email.toLowerCase().includes(searchFilters.email.toLowerCase())); 
        return matchesName && matchesEmail;
      });
      setFilteredData(filtered);
    }
  }, [users, searchFilters]);

  const onApplySearch = (search) => {
    setSearchFilters(search); 
  };

  const columns = [
    {
      title: "Name",
      editable: false,
      dataIndex: "name",
      align: "left",
    },
    {
      title: "Email",
      editable: false,
      dataIndex: "email",
      align: "left",
    },
    {
      title: "Role",
      editable: false,
      dataIndex: "role",
      align: "left",
    },
    {
      title: "Status",
      editable: false,
      dataIndex: "status",
      align: "left",
    },
    {
      title: "Actions",
      editable: false,
      dataIndex: "Actions",
      align: "left",
      render: (_, rowData) => {
        return (
          <Flex gap={16} align={"center"}>
            <div className={css.actionContainer}>
              <span
                className={css.actionIcon}
                onClick={() => {
                  dispatch(clearData());
                  navigate(`/admin/users/edit`, { state: { rowData } });
                }}
              >
                <IconEdit />
              </span>
            </div>
            <div className={css.actionContainer}>
              <Popconfirm
                title="Are you sure you want to delete this user?"
                onConfirm={() => {
                  dispatch(deleteUser(rowData.id))
                }}
                okText="Yes"
                cancelText="No"
              >
                <span className={css.actionIcon}>
                <DeleteFilled />
                </span>
              </Popconfirm>
            </div>
          </Flex>
        );
      },
    },
  ];

  const handleCreateUserClick = () => {
    navigate("/admin/users/new");
  };

  return (<>
    <Breadcrumbs 
      basePath="admin" 
      paths={[
        { path: '/users', breadcrumbName: 'User Management' },
      ]}
    />
    <PageHeader title="Users">
      <Button
        type="primary"
        onClick={handleCreateUserClick}
      >
        Create User
      </Button>
    </PageHeader> 
    <Flex className={css.root} vertical>
      <div className={"ibox-holder"}>
        <UserSearch onApply={onApplySearch} />
      </div>
      <div className={"ibox-holder"}>
        <Table
          components={{
            header: {
              row: HeaderCell,
            },
          }}
          columns={columns}
          rowKey={(user) => user.id}
          dataSource={filteredData}
          pagination={{
            position: ["bottomRight"],
            responsive: true,
            pageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          loading={getUsersInProgress}
          style={{ marginTop: 10 }}
          scroll={{ x: "100%" }}
        />
      </div>
    </Flex>
    </>
  );
};
