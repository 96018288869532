import moment from "moment";

const { REACT_APP_RIOT_URL, REACT_APP_RIOT_API_USER, REACT_APP_RIOT_API_TOKEN } = process.env;

export const getId = (pathname) => {
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const id = parseInt(lastPart);

  if (!isNaN(id)) return id;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatDate = (value) => {
  if (!value || value === 'null') return;

  const date = moment(value);
  return date.format("MM/DD/YY");
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const buildQueryString = (path, params) => {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return `${path}?${queryString}`;
};

export const checkUrl = (url, template) => {
  return url.startsWith(template);
}

export const authHeaderOrthus = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { "x-user-id": user.id, "x-access-token": user.token, "x-data-silo-code": user.data_silo_codes };
  } else {
    return {};
  }
}

export const authHeader = () => {
  const headers = {"X-Auth-Token": REACT_APP_RIOT_API_TOKEN}
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { "X-User-Id": user.id, "X-Person-Token": user.token, "X-Data-Silo-Code": 'cgo',  "X-Imprints": user.imprint_ids, ...headers };
  } else {
    return headers;
  }
}


export const generateDateRange = (start, end) => {
  start = moment(start);
  end = moment(end);
  let dates = [start.format('YYYY-MM-DD')];
  
  while(start.add(1, 'days').diff(end) <= 0) {
      dates.push(start.clone().format('YYYY-MM-DD'));
  }
  return dates;
}

export const generateMonthRange = (start, end) => {
  start = moment(start);
  end = moment(end);
  let dates = [start.format('MMM-YYYY')];
  
  while(start.add(1, 'month').diff(end, 'months') < 0) {
      dates.push(start.clone().format('MMM-YYYY'));
  }
  return dates;
}