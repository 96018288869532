import React from "react";
import { Col, Flex, Layout, Row } from "antd";
import css from "./Footer.module.css";
import { QuestionCircleFilled } from '@ant-design/icons';
const { Footer: AntFooter } = Layout;

export const Footer = () => {
  return (
    <AntFooter className={css.footer}>
      <Flex vertical={false} align="center" justify="space-between" className={css.footerInner}>
        <Row className={css.rowLeft}>
          <Col>
            <a href="https://babelops.notion.site/137d27bba73e80cfa149c161444ad201?v=137d27bba73e81ae8411000c20d8957b&pvs=4" target="_blank" className={css.help}>Help Articles <QuestionCircleFilled /></a>
          </Col>
          <Col className={css.copy}>Copyright ©️ 2024</Col>
        </Row>
        <Row gutter={16} className={css.rowRight}>
          <Col className={css.col}><a href="#">TERMS</a></Col>
          <Col className={css.col}><a href="#">PRIVACY</a></Col>
          <Col className={css.col}><a href="#">DO NOT SELL MY PERSONAL INFORMATION</a></Col>
        </Row>
      </Flex>
    </AntFooter>
  );
};
