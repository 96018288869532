import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const dateCell = (record, format='YYYY-DD-MM', spanClassName = '') => {
	let value = record;	
	if(record && record.hasOwnProperty('value'))
		value = record.value;	
	return (
		<span className={spanClassName}>{value ? dayjs(value, format).format('YYYY-DD-MM') : 'N/A'}</span>
	)
}