import React from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb } from 'antd';
import css from './Breadcrumbs.module.css';

function itemRender(currentRoute, params, items, paths) {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  return isLast ? (
	<span>{currentRoute.title}</span>
  ) : (
	<Link to={`${currentRoute.path}`}>{currentRoute.title}</Link>
  );
}

export const Breadcrumbs = ({paths = []}) => {
  const defaultPaths = [
	{
	  path: '/stocks',
	  breadcrumbName: 'Stocks',
	},
	{
	  path: '/stocks_sales',
	  breadcrumbName: 'Stocks and Sales',
	}  
  ];
  return <Breadcrumb
	  itemRender={itemRender} 
	className={css.breadcrumbs}
	routes={defaultPaths.concat(paths)}
  />
};