// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs_breadcrumbs__xrVnQ {
  padding: 20px;
  background-color: #fff;

  & ol li:last-child a {
    color: #000;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB;IACE,WAAW;EACb;AACF","sourcesContent":[".breadcrumbs {\n  padding: 20px;\n  background-color: #fff;\n\n  & ol li:last-child a {\n    color: #000;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `Breadcrumbs_breadcrumbs__xrVnQ`
};
export default ___CSS_LOADER_EXPORT___;
