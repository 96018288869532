// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiSelectTransfer_root__HET78 {
  display: flex;
  gap: 20px;
  
}

.MultiSelectTransfer_buttons__lMZOT {
  display: flex;
  flex-direction: column;
  gap: 5px;

  & button {
    line-height: 1;
    height: 24px;
  }
}

.MultiSelectTransfer_item__5IDm4 {
  text-align: left;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc((100% - 87px)/2);

  & .ant-select-dropdown {
    position: static;
    margin-top: 5px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    height: 266px;
  }

  & label {
    margin-bottom: 5px;
    display: block;
  }

  & .ant-select-dropdown-empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MultiSelectTransfer/MultiSelectTransfer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;;AAEX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;;EAER;IACE,cAAc;IACd,YAAY;EACd;AACF;;AAEA;EACE,gBAAgB;EAChB,YAA+B;EAA/B,cAA+B;EAA/B,iCAA+B;;EAE/B;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".root {\n  display: flex;\n  gap: 20px;\n  \n}\n\n.buttons {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n  & button {\n    line-height: 1;\n    height: 24px;\n  }\n}\n\n.item {\n  text-align: left;\n  flex: 1 0 calc((100% - 87px)/2);\n\n  & :global(.ant-select-dropdown) {\n    position: static;\n    margin-top: 5px;\n    box-shadow: none;\n    border: 1px solid #d9d9d9;\n    height: 266px;\n  }\n\n  & label {\n    margin-bottom: 5px;\n    display: block;\n  }\n\n  & :global(.ant-select-dropdown-empty) {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MultiSelectTransfer_root__HET78`,
	"buttons": `MultiSelectTransfer_buttons__lMZOT`,
	"item": `MultiSelectTransfer_item__5IDm4`
};
export default ___CSS_LOADER_EXPORT___;
