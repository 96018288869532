export const accountTypeStatusOptions = [
  {
    "key": "HFA Agreement Code",
    "value": "hfa_agreement_code"
  },
  {
    "key": "Manufacturer Number",
    "value": "manufacturer_number"
  },
  {
    "key": "Transaction Date",
    "value": "transaction_date"
  },
  {
    "key": "Label Name",
    "value": "label_name"
  },
  {
    "key": "Total Playing Time - Minutes",
    "value": "total_playing_time_minutes"
  },
  {
    "key": "Artist Name",
    "value": "artist_name"
  },
  {
    "key": "A/K/A Song Title",
    "value": "aka_song_title"
  },
  {
    "key": "ISWC Code",
    "value": "iswc"
  },
  {
    "key": "HFA Song Code",
    "value": "hfa_song_code"
  },
  {
    "key": "Composer(s)",
    "value": "composers"
  },
  {
    "key": "Catalog Number",
    "value": "catalog_number"
  },
  {
    "key": "Album Title",
    "value": "album_title"
  },
  {
    "key": "Configuration Code",
    "value": "configuration_code"
  },
  {
    "key": "License Type",
    "value": "license_type"
  },
  {
    "key": "Server Fixation Date",
    "value": "server_fixation_date"
  },
  {
    "key": "Rate Code",
    "value": "rate_code"
  },
  {
    "key": "HFA Output Field #1",
    "value": "hfa_output_field_1"
  },
  {
    "key": "HFA Output Field #2",
    "value": "hfa_output_field_2"
  },
  {
    "key": "HFA Output Field #3",
    "value": "hfa_output_field_3"
  },
  {
    "key": "HFA License Number or NOI Reference ID",
    "value": "hfa_license_or_noi_reference"
  },
  {
    "key": "HFA Request Status Code #1",
    "value": "hfa_request_status_code_1"
  },
  {
    "key": "HFA Request Status Code #2",
    "value": "hfa_request_status_code_2"
  },
  {
    "key": "HFA Request Status Code #3",
    "value": "hfa_request_status_code_3"
  },
  {
    "key": "HFA Request Status Code #4",
    "value": "hfa_request_status_code_4"
  },
  {
    "key": "Licensed Status",
    "value": "licensed_status"
  },
  {
    "key": "HFA Output Field #4",
    "value": "hfa_output_field_4"
  },
  {
    "key": "Total HFA Licensed Share",
    "value": "total_hfa_licensed_share"
  },
  {
    "key": "Total Playing Time - Seconds",
    "value": "total_playing_time_seconds"
  },
  {
    "key": "User Defined 41",
    "value": "user_defined_1"
  },
  {
    "key": "User Defined 42",
    "value": "user_defined_2"
  },
  {
    "key": "User Defined 43",
    "value": "user_defined_3"
  },
  {
    "key": "User Defined 44",
    "value": "user_defined_4"
  },
  {
    "key": "User Defined 45",
    "value": "user_defined_5"
  }
]

const storeDistro = [
  'Artist',
  'Band',
  'Cargo Mailorder',
  'Distributor',
  'Label',
  'Misc',
  'Staff',
  'Store',
  'Store / Artist',
  'Store / Distributor'
]

const grouping = [
  'Amazon UK',
  'Artist',
  'Cargo Mailorder',
  'Distributor - Export',
  'HMV / Sunrise',
  'Indie Store - Export',
  'Indie Store - Ireland',
  'Indie Store - UK',
  'Label Purchase',
  'Misc',
  'Staff Purchase',
  'Wholesaler'
]

const exportDomestic = [
  'Export',
  'Domestic'
]

const wrapOption = (values) => values.map(value=>({key: value, value}))
const wrapLabel = (values) => values.map(value=>({label: value, value}))

export const storeDistroOptions = wrapOption(storeDistro)
export const groupingOptions = wrapOption(grouping)
export const exportDomesticOptions = wrapLabel(exportDomestic)