import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAccountTypesRequest,
  updateAccountTypeRequest,
} from "../../api";

const initialState = {
  accountTypes: [],
  getAccountTypesInProgress: false,
  getAccountTypesError: null,
  accountType: null,
  getAccountTypeInProgress: false,
  getAccountTypeError: null,
  updateAccountTypeInProgress: false,
  updateAccountTypeError: null,
};

export const getAccountTypes = createAsyncThunk(
  "accountTypes/getAccountTypes",
  async (_, { rejectWithValue }) => {
    try {
      const { page, search, sort, status } = _;
      const response = await getAccountTypesRequest({
        page,
        search,
        sort,
        status
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateAccountType = createAsyncThunk(
  "accountTypes/updateAccountType",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = _;
      const response = await updateAccountTypeRequest(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const accountTypesSlice = createSlice({
  name: "accountTypes",
  initialState,
  reducers: {
    clearData(state) {
      state.accountType = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountTypes.pending, (state) => {
        state.getAccountTypesInProgress = true;
        state.getAccountTypesError = null;
      })
      .addCase(getAccountTypes.fulfilled, (state, action) => {
        let totalPages = 0,
        {payload} = action;
        if(Array.isArray(payload) && payload.length > 0) {
          totalPages = payload[0].total_rows;
        }  
        state.accountTypes = {data: payload, totalPages};
        state.getAccountTypesInProgress = false;
        state.getAccountTypesError = null;
      })
      .addCase(getAccountTypes.rejected, (state, action) => {
        state.getAccountTypesInProgress = false;
        state.getAccountTypesError = action.payload;
      })
      .addCase(updateAccountType.pending, (state) => {
        state.updateAccountTypeInProgress = true;
        state.updateAccountTypeError = null;
      })
      .addCase(updateAccountType.fulfilled, (state, action) => {
        state.updateAccountTypeInProgress = false;
        state.updateAccountTypeError = null;
      })
      .addCase(updateAccountType.rejected, (state, action) => {
        state.updateAccountTypeInProgress = false;
        state.updateAccountTypeError = action.payload;
      });
  },
});

export const { clearData } = accountTypesSlice.actions;

// this is for configureStore
export default accountTypesSlice.reducer;
