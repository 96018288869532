import { configureStore } from "@reduxjs/toolkit";

import dashboardReducer from "../views/Dashboard/dashboardSlice";
import searchReducer from "../views/SearchBox/searchSlice";
import accountTypesReducer from "../views/AccountTypes/accountTypesSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    search: searchReducer,
    accountTypes: accountTypesReducer
  },
});
