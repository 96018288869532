import React, { useState } from "react";
import { Layout, Flex, Row } from "antd";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { MainLogo } from "../MainLogo/MainLogo";
import { MainMenu } from "../MainMenu/MainMenu";
import { IconArrow } from "../IconArrow/IconArrow";
import { Footer } from "../Footer/Footer";
import { IconLogout } from "../IconLogout/IconLogout";
import { checkUrl } from "../../utils/helpers";
import { useAuth } from "../../store/AuthContext";

import css from "./MainLayout.module.css";

const { Header, Sider, Content } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "#171717",
  height: 48,
  lineHeight: "48px",
  backgroundColor: "#ffffff",
  borderBottom: "1px solid #73737333",
};

const contentStyle = {
  textAlign: "center",
  color: "#171717",
  margin: 0,
};

const siderStyle = {
  textAlign: "center",
  color: "#171717",
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  position: "relative",
  borderRight: "1px solid #E3E3E3",
  boxShadow: "1px -1px 1px 0px rgba(0,0,0,0.26)",
  left: 0,
  top: 0,
  bottom: 0,
};

export const MainLayout = ({ children }) => {
  const { user, logout } = useAuth(); 
  const [breakPoint, setBreakPoint] = useState({
    width: 208,
  });
  const location = useLocation();
  const { pathname } = location;

  const layoutStyle = {
    width: "100%",
    maxWidth: "100%",
  };

  return (
    <Flex wrap="wrap">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <Flex align="center" justify="space-between">
            <Row className={css.logoContainer}>
              <Link to="/"> <MainLogo /> </Link>
            </Row>
            {user && <Flex
              align="center"
              gap={10}
              justify="flex-end"
              style={{ height: "100%", cursor: "pointer" }}
              onClick={logout}
            >
              <span className={css.logoutText}>{user.name} </span>
              <IconLogout className={css.logoutIcon} />
            </Flex>}
          </Flex>
        </Header>
        <Layout>
          <Sider
            width={breakPoint?.width}
            style={siderStyle}
            breakpoint="md"
            trigger={null}
            onBreakpoint={(broken) => {
              if (broken) {
                setBreakPoint({ width: "100%" });
              } else {
                setBreakPoint({ width: 208 });
              }
            }}
          >
            <div>
              <MainMenu />
            </div>
          </Sider>
          <Layout>
            <Content style={contentStyle}>{children}</Content>
          </Layout>
        </Layout>
      </Layout>
      <Footer />
    </Flex>
  );
};
