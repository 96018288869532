import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const dateCell = (record, format='YYYY-MM-DD') => {
	let value = record;	
	if(record && record.hasOwnProperty('value'))
		value = record.value;	
	return (
		<span>{dayjs(value, format).format('YYYY-MM-DD')}</span>
	)
}