import { ConfigProvider, Table } from "antd";

export const TableWithRow = (props) => {
  return (
	  <ConfigProvider
		  theme={{
			  components: {
				  Table: {
					  rowSelectedBg: '#ba80f0'
				  }
			  }
		  }}
	  >
	  	<Table {...props} />
	  </ConfigProvider>
  )
}