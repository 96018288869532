export const IconEdit = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.82658 15C2.59563 14.9996 2.37546 14.9022 2.2198 14.7316C2.06127 14.5624 1.9825 14.3335 2.00327 14.1026L2.20498 11.8846L11.5231 2.56977L14.4352 5.48097L5.11948 14.795L2.9015 14.9967C2.87598 14.9992 2.85045 15 2.82658 15ZM15.0164 4.89889L12.1052 1.98769L13.8514 0.241464C14.0059 0.0868664 14.2154 0 14.4339 0C14.6524 0 14.862 0.0868664 15.0164 0.241464L16.7626 1.98769C16.9172 2.14212 17.0041 2.35166 17.0041 2.57018C17.0041 2.78869 16.9172 2.99824 16.7626 3.15266L15.0172 4.89807L15.0164 4.89889Z"
        fill="#2E3A59"
      />
    </svg>
  );
};
