import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
  getLabelsRequest, 
} from "../../api";

const initialState = {
  labels: [],
  getLabelsInProgress: false,
  getLabelsError: null,
};

export const getLabels = createAsyncThunk(
  "labels/getLabels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getLabelsRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    clearData(state) {
      state.labels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLabels.pending, (state) => {
        state.getLabelsInProgress = true;
        state.getLabelsError = null;
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        const payload = action.payload;
        const labelsData = payload.labels || [];

        state.labels = labelsData;
        state.getLabelsInProgress = false;
        state.getLabelsError = null;
      })
      .addCase(getLabels.rejected, (state, action) => {
        state.getLabelsInProgress = false;
        state.getLabelsError = action.payload;
      })
  },
});

export const { clearData } = labelsSlice.actions;

export default labelsSlice.reducer;
