import React from "react";
import { Col, Flex, Form, Input, Row, Button } from "antd";

import css from "../Dashboard/Dashboard.module.css";

export const UserSearch = ({ onApply }) => {
  const [form] = Form.useForm();

  const handleSearch = () => {
    form.validateFields().then((values) => {
      onApply(values);
    });
  };

  const handleReset = () => {
    form.resetFields();
    onApply({ name: '', email: '' });
  };

  return (
    <Flex className={""} vertical>
      <Form
        name="userSearchForm"
        initialValues={{ name: '', email: '' }}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16} className={css.searchFormContainer}>
          <Col xs={24} md={24} lg={12} xxl={12} className={css.formItem}>
            <label htmlFor="name">Name:</label>
            <Form.Item name="name">
              <Input size="default" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={8} xxl={8} className={css.formItem}>
            <label htmlFor="email">Email:</label>
            <Form.Item name="email">
              <Input size="default" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={4} xxl={4} className={css.actionsWrapper}>
            <Button type="default" htmlType="button" onClick={handleReset}>
              Reset
            </Button>
            <Button type="primary" htmlType="button" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Flex>
  );
};
