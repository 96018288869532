import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../store/AuthContext";
import { Col, Form, Input, Row, Select, Button, Flex, message } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { MainButton } from "../../components/MainButton/MainButton";
import { updatePassword } from "../Users/usersSlice";

import css from "./ChangePassword.module.css";

export const ChangePassword = () => {
  const { logout } = useAuth();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const result = await dispatch(updatePassword(values));
    if (result.meta.requestStatus === "fulfilled") {
      message.success("Password changed successfully. Please log in again.");
      logout();
      navigate("/login");
    }
  };

  const rules = [
    {
      required: true,
      message: "This field is required",
    },
  ];

  const validateConfirmPassword = (_, value) => {
    if (!value || form.getFieldValue("new_password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("The new passwords do not match!"));
  };

  return (<>
    <Breadcrumbs 
      basePath="user" 
      paths={[
        { path: '/user/:id/change-password', breadcrumbName: 'Change Password' },
      ]}
    />
   
    <Flex className={css.root} vertical>
      <div className={"ibox-holder"}>
        <Form
          form={form}
          name="updatePassword"
          style={{ marginTop: 10, marginBottom: 0 }}
          autoComplete="off"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="old_password"
                className={css.formItem}
                label={<span className={css.label}>Current password</span>}
                rules={rules}
              >
                <Input
                  size="large"
                  placeholder="Enter password"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="new_password"
                className={css.formItem}
                label={<span className={css.label}>New password</span>}
                rules={rules}
              >
                <Input
                  size="large"
                  placeholder="Enter password"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="new_password_confirm"
                className={css.formItem}
                label={<span className={css.label}>Confirm password</span>}
                rules={[
                  ...rules,
                  { validator: validateConfirmPassword },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter password"
                  style={{ color: "#737373" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item>
                <MainButton
                  size="medium"
                  rootClassName={css.submitButton}
                  htmlType="submit"
                  loading={false}
                >
                  Update password
                </MainButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Flex>
    </>
  );
};
